$min-height: 100vh;
$header-height: 120px;
$header-padding: 6px;
$footer-height: 120px;
$wfg-gold: #A39161;
$wfg-light-blue: #A8C2C8;
$wfg-blue: #6B7A6B;
$input-LR-padding: 4rem;
$top-border-thickness: 4px;
$body-height: calc(100vh - #{$footer-height} - #{$header-height} - #{$header-padding * 2});
$carousel-height: 600px;
$carousel-image-height: calc(#{$carousel-height} * 0.85);
$carousel-width: calc(#{($carousel-height * 0.85) / 6 * 4.25});

a, a:link, a:visited {
  text-decoration: none;
  color: white;
}

a:hover, a:active {
  color: #6B7A6B;
}

button {
  font-family: 'Roboto', sans-serif;
  height: 2.6em;
  width: 12em;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-size: 1em;
  margin-top: 8px;
}

button:hover {
  cursor: pointer;
}

button[type="submit"] {
  transition: background-color 0.2s ease;

  i.fa.fa-arrow-right {
    opacity: 0;
    width: 0;
    visibility: hidden;
    transition: margin 0.2s ease;
  }
}

button[type="submit"]:hover {
  border-color: $wfg-gold;
  background-color: $wfg-gold;
  color: white;

  i.fa.fa-arrow-right {
    margin-left: 8px;
    opacity: 1;
    visibility: visible;
  }
}

hr {
  background-color: $wfg-gold;
  border-radius: 2px;
  height: 2px;
  width: 100%;
  margin: 1rem 0;
}

input {
  font-family: 'Roboto', sans-serif;
  height: 2em;
  width: 12em;
  outline: 0;
  border-width: 0;
  border-color: #282c34;
  margin: 8px 0;
  font-size: 1em;
  transition: border-color 0.2s ease-in, border-width 0.2s ease-in;
  padding: 0.3rem 1rem;
}

input:focus::placeholder {
  //color: transparent;
}

input::placeholder {
  color: white;
}

.line {
  height: 2px;
  width: 0;
  background-color: $wfg-gold;
  transition: .2s width ease-in-out;
  position: relative;
  top: -2px;
}

input:focus + .line {
  width: calc(80% + #{$input-LR-padding});
}

ul {
  list-style: none;
}

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
  background: #282c34 url("img/background.jpg") no-repeat fixed top;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
  }

  p {
    color: #676767;
  }
}

.App-header {
  height: $header-height;

  .container {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 4vw;
    font-size: 1.4em;
    height: 100%;
    width: 100%;
    color: $wfg-gold;
    box-shadow: 0 1px 2px 2px white;

    img {
      width: 300px;
      transition: opacity 0.2s ease-in;
    }

    img:hover {
      opacity: 0.4;
    }
  }
}

.App-body {

  > .login.loaded, > .showcase.loaded {
    opacity: 1;
  }

  > .login {
    flex: 1;
    min-height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    margin-top: 1rem;
    color: white;
    transition: opacity 0.6s ease-in;
    opacity: 0;

    > .container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 0 2rem 6rem;

      > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 1rem;
        margin: 2rem 0;
        width: 100%;
        max-height: 90%;

        > div.auth {
          background-color: transparent;
          backdrop-filter: blur(3px);
          border: 1px solid rgba(255,255,255, 0.3);
          padding: 2rem;

          .themed-input {
            position: relative;
            color: white;
            height: 4rem;

            i.fa {
              position: absolute;
              top: 0.5rem;
              font-size: 1.6em;
            }

            input {
              padding: 0 2rem;
              color: white;
            }
          }

          input {
            background-color: transparent;
            border-bottom: 2px solid white;
            margin-bottom: 0;
            text-align: left;
          }

          button {
            width: 240px;
            height: 48px;
            margin: 1.5rem 0;
          }

          span {
            font-size: 1.2em;
          }

          > p {
            padding-top: 0.7rem;
            font-size: 0.8em;
          }
        }

        > div.support {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border-top: none;

          > div.hug-top {
            text-align: left;
            align-items: flex-start;
            font-size: 1.3em;
            width: 100%;
            margin-top: 1rem;

            ul {
              font-size: 0.8em;
              padding: 0;
            }
          }

          > div.hug-bottom {
            text-align: center;

            > p {
              font-size: 0.7em;
            }
          }
        }
      }

      > div.preview {
        display: none;
      }
    }
  }

  > .showcase {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 1px 2px 2px rgba(255, 255, 255, 0.9);
    font-size: 1.2em;
    transition: opacity 0.6s ease-in;
    opacity: 0;

    > .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      width: 80%;
      max-width: 1200px;

      > .section {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;

        > div.image {
          flex: 1;
          order: 1;
        }

        > p.description {
          flex: 2;
          padding: 1rem;
          text-align: left;
        }
      }
    }
  }
}

.App-footer {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: $footer-height;
  background-color: #252525;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 1px 1px #252525;

  span {
    color: white;
    padding-bottom: 0.4rem;
  }

  > div.links {

    a {
      padding: 0 1rem;
      color: white;
      line-height: 2em;
    }

    a:hover {
      color: $wfg-blue;
    }

  }

  span.copyright p {
    color: white;
  }
}

@media only screen and (min-width: 769px) {
  button {
    height: 2.6em;
    width: 12em;
    font-size: 1.2em;
    margin-top: 8px;
  }

  input {
    height: 2em;
    width: 12em;
    margin: 8px 0;
    font-size: 1.4em;
    padding: 0.3rem 1rem;
  }

  .line {
  }

  input:focus + .line {
    width: calc(80% + #{$input-LR-padding});
  }

  .App {
  }

  .App-header {

    .container {
      padding-left: 10vw;

      img {
        width: auto;
      }
    }
  }

  .App-body {
    flex-direction: row;

    .background {
    }

    > .login {
      flex-direction: row;
      min-height: 45vh;

      > .container {
        flex-direction: row;

        > form {
          padding-left: 2rem;

          > div.auth {

            .themed-input {
              height: 4rem;

              i.fa {
                top: 1rem;
                font-size: 1.6em;
              }

              input {
                padding: 0 2rem;
              }
            }

            input {
            }

            button {
              width: 300px;
            }

            a {
            }
          }

          > div.support {

            ul {
            }
          }
        }

        > div.preview {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          visibility: hidden;

          .carousel.carousel-slider {
            height: $carousel-height;
            width: $carousel-width !important;
            color: white;

            ul {
              padding: 0;
            }

            .slide {
              background: $wfg-gold;
            }

            .slider-wrapper, .slider, .slide {
              height: $carousel-height;
            }

            .slide > div > img {
              height: $carousel-image-height;
              border-bottom: $top-border-thickness solid black;
            }

            .control-dots .dot {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }

    > .showcase {
      margin: 0 2%;

      > .container {

        > .section {
          padding: 1rem;

          > div.image {
          }
        }
      }
    }
  }

  .App-footer {
  }
}

@media only screen and (min-width: 1080px) {
  .App-body {
    > .login {
      > .container {
        width: 50%;
        padding: 1rem 4rem 4rem;

        > form {
          flex-direction: row;

          > div.support {
          }
        }
      }
    }

    > .showcase {

      > .container {
        > .section {
          flex-direction: row;

          > div.image {
            order: 0;
          }
        }
      }
    }
  }
}